import * as queryString from "query-string";
import * as singleSpa from "single-spa";

const HandleRedirects = () => {
  const { search } = window.location;
  const defaultRoute = "/s/evaluations";
  const { id, pid } = queryString.parse(search);
  singleSpa.navigateToUrl(id && pid ? `/s/evaluation/${id}/product/${pid}/scoresheet${search}` : defaultRoute);
  return null;
};

export default HandleRedirects;
