import React, { FunctionComponentElement, ReactNode, useState } from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import { handleEvent } from "gx-npm-lib";
import { rootStyles } from "./list.styles";

interface CommonProps {
  // Define the common props shared by the original children elements
  // For example:
  expand: boolean;
  setExpand: (val: boolean) => void;
  in: boolean;
}

type ListProps = {
  children: ReactNode;
  defaultExpand?: boolean;
  handleExpand?: (val: boolean) => void;
  rootClassName?: string;
};

const useStyles = makeStyles(() => rootStyles);
const List = ({ children, defaultExpand = true, handleExpand, rootClassName = "" }: ListProps) => {
  const classes = useStyles();
  const [expand, setExpandCollapse] = useState(defaultExpand);

  const setExpand = (val: boolean) => {
    setExpandCollapse(val);
    handleEvent(handleExpand, val);
  };

  const childrenWithProps = React.Children.map(children, (child) => {
    // Use type assertion (as) to specify the correct type for the child
    const childElement = child as FunctionComponentElement<CommonProps>;

    return React.isValidElement(childElement)
      ? React.cloneElement(childElement, { expand: expand, setExpand, in: expand })
      : child;
  });

  return <section className={classNames("list", classes.root, rootClassName)}>{childrenWithProps}</section>;
};

export default List;
