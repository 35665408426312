import { Box, Collapse, IconButton, makeStyles } from "@material-ui/core";
import classNames from "classnames";
import { colorPalette } from "gx-npm-common-styles";
import { ArrowCircledIcon } from "gx-npm-icons";
import { TextLink, TypographyComponent } from "gx-npm-ui";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./faq-section.styles";

const useStyles = makeStyles(() => styles);
const FaqSection = ({ disabled = false, isViewer = true }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  function mouseEnter() {
    setIsHovered(true);
  }

  function mouseLeave() {
    setIsHovered(false);
  }

  function handleClick() {
    setIsExpanded(!isExpanded);
  }

  let arrowIconColorFill = colorPalette.neutrals.pearl.hex;
  if (disabled || isViewer) {
    arrowIconColorFill = colorPalette.neutrals.iron.hex;
  } else if (isHovered) {
    arrowIconColorFill = colorPalette.neutrals.carbon.hex;
  }

  return (
    <Box aria-label="faq section" className={isViewer ? classes.faqSectionViewer : classes.faqSection}>
      {!isViewer && (
        <Box className={classNames("gx-workspace-faq-divider", disabled ? classes.dividerGray : classes.divider)} />
      )}

      <IconButton
        className={classNames(classes.iconBtn, isExpanded && "expanded")}
        onClick={() => handleClick()}
        onMouseEnter={() => mouseEnter()}
        onMouseLeave={() => mouseLeave()}
      >
        <ArrowCircledIcon title={isExpanded ? "collapse" : "expand"} fillPath={arrowIconColorFill} />
      </IconButton>
      <TypographyComponent boldness="semi" color="white" element="span">
        {t("Scoring FAQs")}
      </TypographyComponent>
      <Collapse in={isExpanded}>
        <Box className={classes.faqs}>
          <TypographyComponent rootClassName={classes.faqQuestion} color={"white"} styling={"p3"} boldness={"medium"}>
            {t("How will my scores be used?")}
          </TypographyComponent>
          <TypographyComponent rootClassName={classes.faqAnswer} color={"white"} styling={"p3"}>
            {t(
              "Your requirement scores will be averaged with scores entered by other assigned team members, generating a single requirement score."
            )}
          </TypographyComponent>
          <TypographyComponent rootClassName={classes.faqQuestion} color={"white"} styling={"p3"} boldness={"medium"}>
            {t("What does it mean to 'Opt out'?")}
          </TypographyComponent>
          <TypographyComponent rootClassName={classes.faqAnswer} color={"white"} styling={"p3"}>
            {t(
              "Opting out of a requirement completes your assignment of that requirement, leaving the team requirement score to be averaged by only other assigned team members."
            )}
          </TypographyComponent>

          <TextLink
            href="https://intercom.help/gartner-buysmart-help-center/en/articles/6276044-how-scoring-works"
            rootClassName={classNames(classes.scorecardLink, isViewer && "viewer")}
            target="_blank"
            text={t("Learn how Scorecards are calculated")}
            variant="secondary"
          />
        </Box>
      </Collapse>
    </Box>
  );
};

export default FaqSection;
