import { t } from "i18next";
import { optOut } from "../../../../../../lib";

const scoreMenuItemsList = () => [
  {
    index: 0,
    name: t("No score"),
  },
  {
    index: 1,
    name: t("1    Poor or absent"),
    selectedClassName: "carbon",
    showSelectedIndex: true,
  },
  {
    index: 2,
    name: t("2    Sub-optimal"),
    selectedClassName: "carbon",
    showSelectedIndex: true,
  },
  {
    index: 3,
    name: t("3    Good"),
    selectedClassName: "carbon",
    showSelectedIndex: true,
  },
  {
    index: 4,
    name: t("4    Great"),
    selectedClassName: "carbon",
    showSelectedIndex: true,
  },
  {
    index: 5,
    name: t("5    Excellent"),
    selectedClassName: "carbon",
    showSelectedIndex: true,
  },
];

const scorePriorityMap: () => Record<number, string> = () => ({
  0: t("None"),
  1: t("Low"),
  2: t("Medium"),
  3: t("High"),
});

const scoreDescriptionMap: () => Record<number, string> = () => ({
  0: t("No score"),
  1: t("1/5 Poor or absent"),
  2: t("2/5 Sub-optimal"),
  3: t("3/5 Good"),
  4: t("4/5 Great"),
  5: t("5/5 Excellent"),
});

/**
 * gets score description
 * @param {*} val index of description to get
 * @returns a string description if val is valid, otherwise defaults to No score
 */

const getScoreDescription = (val: number) => {
  return scoreDescriptionMap()[val] || scoreDescriptionMap()[0];
};

/**
 * gets score menu items
 * @param {boolean} isOptOutIncluded whether to include the "Opt out" option or not
 * @returns final score menu items object
 */
const getScoreMenuItemsList = (isOptOutIncluded = false) => {
  if (isOptOutIncluded) {
    const itemOptOut = { index: optOut.internalScore, name: t("Opt out"), selectedClassName: "carbon" };
    return [...scoreMenuItemsList(), itemOptOut];
  }
  return scoreMenuItemsList();
};

/**
 * gets score priority
 * @param {*} val index of priority to get
 * @returns a string priority if val is valid, otherwise defaults to None
 */
const getScorePriority = (val: number) => {
  return scorePriorityMap()[val] || scorePriorityMap()[0];
};

export { getScoreDescription, getScoreMenuItemsList, getScorePriority };
