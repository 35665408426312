import { useScoresheetContext } from "./scoresheet.context";
import { InitProdState, InitStatus, InitUserRole, ScorecardProgress, ScoringMode } from "gx-npm-lib";

export type Scoresheet = {
  initiativeId: string;
  initStatus: InitStatus | "";
  isEvaluator: boolean;
  overallScore: number;
  productState: InitProdState | "";
  scorecardProgress: ScorecardProgress | "";
  scoringMode: ScoringMode | "";
  userRole: InitUserRole | "";
};
const useScoresheet = (): Scoresheet => {
  const {
    initiativeId,
    initStatus,
    isEvaluator,
    overallScore,
    scorecardProgress,
    scoringMode,
    userRole,
    productState,
  } = useScoresheetContext();

  return {
    initiativeId,
    initStatus,
    isEvaluator,
    overallScore,
    productState,
    scorecardProgress,
    scoringMode,
    userRole,
  };
};

export default useScoresheet;
