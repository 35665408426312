import { isValidResponse, UUID } from "gx-npm-lib";
import { ItemScoreData, ScoresheetRequirementsCatDto } from "../../sections/score-content/score-content.types";
import { postRequest } from "../requests";

const saveItemScore = async (
  config: {
    initiativeId: UUID;
    initProductId: UUID;
    catId: UUID;
    itemId: UUID;
    categories: ScoresheetRequirementsCatDto[];
    value: number;
  },
  itemScoreData: ItemScoreData,
  onSuccessResponse: (newCategories: ScoresheetRequirementsCatDto[], overallScore: number) => void,
  onErrorResponse: (error: string | object) => void
) => {
  const { value, initiativeId, initProductId, categories, catId, itemId } = config;
  const url = `/api/v2/initiatives/${initiativeId}/products/${initProductId}/scorecard/category/${catId}/item/${itemId}/user`;
  const response = await postRequest(url, itemScoreData);
  if (isValidResponse(response)) {
    const newCategories = categories.map((category) => {
      if (category.id === catId) {
        const newItems = category.items.map((item) => {
          if (item.id === itemId) {
            return { ...item, score: value };
          }
          return item;
        });
        return {
          ...category,
          items: newItems,
          score: response.data.data.categoryScore,
        };
      }
      return category;
    });
    onSuccessResponse(newCategories, response.data.data.overallScore);
  } else {
    onErrorResponse(response);
  }
};

export { saveItemScore };
