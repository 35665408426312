import { isValidResponse, UUID } from "gx-npm-lib";
import {
  CommentType,
  ItemCommentData,
  ScoresheetRequirementsCatDto,
} from "../../sections/score-content/score-content.types";
import { postRequest, putRequest } from "../requests";

const saveItemComment = async (
  config: {
    initiativeId: UUID;
    initProductId: UUID;
    itemId: UUID;
    catId: UUID;
    categories: ScoresheetRequirementsCatDto[];
    value: string;
    commentId: UUID;
  },
  itemCommentData: ItemCommentData,
  onSuccessResponse: (newRequirements: ScoresheetRequirementsCatDto[]) => void,
  onErrorResponse: (error: string | object) => void
) => {
  const { value, commentId, initiativeId, initProductId, categories, catId, itemId } = config;
  const userExtension = "/user";
  const urlEnd = commentId ? `/${commentId}${userExtension}` : userExtension;
  const url = `/api/v2/initiatives/${initiativeId}/products/${initProductId}/comments/${CommentType.SCORECARD_REQ_ITEM}${urlEnd}`;
  const asyncReq = commentId ? putRequest : postRequest;
  const response = await asyncReq(url, itemCommentData);
  if (isValidResponse(response)) {
    const newCategories = categories.map((category) => {
      if (category.id !== catId) {
        return category;
      }

      const newItems = category.items.map((item) => {
        if (item.id !== itemId) {
          return item;
        }

        if (commentId) {
          return { ...item, reqItemComment: { id: commentId, description: value } };
        } else {
          return { ...item, reqItemComment: { id: response.data.data.id, description: value } };
        }
      });
      return { ...category, items: newItems };
    });

    onSuccessResponse(newCategories);
  } else {
    onErrorResponse(response);
  }
};

export { saveItemComment };
