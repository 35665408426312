import { useScoresheetContext } from "../scoresheet.context";
import { UUID } from "gx-npm-lib";
import { useEffect, useState } from "react";
import {
  CustomEvents,
  ItemScoreConfigType,
  ScoresheetRequirementsCatDto,
} from "../../sections/score-content/score-content.types";
import { saveItemScore } from "./item-score.lib";
import { updateEditCount } from "../lib";

const useReqItemScore = (catId: UUID, itemId: UUID) => {
  const {
    initiativeId,
    initProductId,
    categories,
    setCategories,
    setOverallScore,
    setCategorySavingCount,
    scoresheetEditCount,
    setScoresheetEditCount,
  } = useScoresheetContext();
  const [score, setScore] = useState<number>(0);

  useEffect(() => {
    const category = categories.find((req) => req.id === catId);
    if (category) {
      const item = category.items.find((req) => req.id === itemId);
      if (item) {
        setScore(item.score);
      }
    }
  }, [catId, itemId, categories]);

  useEffect(() => {
    window.dispatchEvent(
      new CustomEvent(CustomEvents.SCORESHEET_SAVING_COUNT, { detail: { count: scoresheetEditCount } })
    );
  }, [scoresheetEditCount]);

  const onSuccess = (newCategories: ScoresheetRequirementsCatDto[], overallScore: number) => {
    setCategories(newCategories);
    setOverallScore(overallScore);
  };
  const handleChangeItemScore = async (
    config: ItemScoreConfigType,
    onError: (error: string | object) => void = (_error) => {
      /*do nothing*/
    }
  ) => {
    updateEditCount(setScoresheetEditCount, 1);
    setCategorySavingCount((prev) => ({ ...prev, [catId]: prev[catId] + 1 }));
    const { value } = config;
    const data = { score: value };
    const saveItemScoreConfig = { ...config, catId, initiativeId, initProductId, itemId, categories };
    await saveItemScore(saveItemScoreConfig, data, onSuccess, onError).finally(() =>
      updateEditCount(setScoresheetEditCount, -1)
    );

    //decrement categorySavingCount
    setCategorySavingCount((prev) => ({ ...prev, [catId]: prev[catId] - 1 }));
  };

  return { score, handleChangeItemScore };
};

export default useReqItemScore;
