const scoreListStyles = {
  content: {
    "& .gx-scorecard-list-item": {
      display: "block",
      padding: "20px 20px 20px 32px",
      "&:last-of-type": {
        borderRadius: "0px 0px 8px 8px",
        boxShadow: "none",
      },
    },
  },
};

export default scoreListStyles;
