// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GIA1KnAdj1nHSzVvfCsD{align-items:center;display:flex;flex-direction:column;margin-top:80px}.pCcJk9LtV6GqHXEqQADQ{margin-bottom:36px}.VwMT7qEU7qONIdinseks{margin-bottom:16px;max-width:600px}.MgbdqrcN7PyCDpZNInVQ{margin-bottom:32px}`, "",{"version":3,"sources":["webpack://./src/sections/no-scores-view.styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,kBAAA,CACA,YAAA,CACA,qBAAA,CACA,eAAA,CAGF,sBACE,kBAAA,CAGF,sBACE,kBAAA,CACA,eAAA,CAGF,sBACE,kBAAA","sourcesContent":[".container {\n  align-items: center;\n  display: flex;\n  flex-direction: column;\n  margin-top: 80px;\n}\n\n.noScoreImg {\n  margin-bottom: 36px;\n}\n\n.title {\n  margin-bottom: 16px;\n  max-width: 600px;\n}\n\n.subtitle {\n  margin-bottom: 32px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `GIA1KnAdj1nHSzVvfCsD`,
	"noScoreImg": `pCcJk9LtV6GqHXEqQADQ`,
	"title": `VwMT7qEU7qONIdinseks`,
	"subtitle": `MgbdqrcN7PyCDpZNInVQ`
};
export default ___CSS_LOADER_EXPORT___;
