import { colorPalette, weightPalette } from "gx-npm-common-styles";

const colorCarbon = colorPalette.neutrals.carbon.hex;
const colorStone = colorPalette.neutrals.stone.hex;
const weightAmtSemiBold = weightPalette.semiBold.amount;
const weightVarSemiBold = weightPalette.semiBold.variation;
const scoreListHeaderStyles = {
  headerRoot: {
    position: "relative" as string as "relative",
    width: "100%",
    "& $h5OrP1element": {
      fontVariationSettings: weightVarSemiBold,
      fontWeight: weightAmtSemiBold,
      fontSize: "20px",
      "@media (max-width:1500px)": {
        fontSize: "18px",
      },
      maxWidth: "65%",
      "@media (max-width:1024px)": {
        flexBasis: "88%",
        maxWidth: "100%",
      },
    },
    "& .gx-scorecard-expand-button": {
      marginRight: 8,
    },
    "& .gx-cat-scoring-popover": {
      display: "inline-flex",
      marginLeft: "auto",
      marginRight: 20,
    },

    "& $buttonOptOut": {
      marginLeft: "auto",
      marginRight: "10px",
      marginTop: "8px",
      "&.opt-out-button-wrap": { marginLeft: "46px" },
    },
  },

  h5OrP1element: {},
  carbon: {
    color: colorCarbon,
  },
  namePercentSplitter: {
    borderLeft: `1px solid ${colorStone}`,
    height: "22px",
    margin: "0px 16px",
  },
  top: {
    alignItems: "start",
    display: "flex",
    flexDirection: "row" as string as "row",
  },
  headerWrap: {
    display: "flex",
    flex: 1,
    flexWrap: "wrap" as string as "wrap",
    alignItems: "center",
    "&.multiline-centered": {
      alignItems: "start",
    },
  },
  right: {
    alignItems: "start",
    display: "flex",
    order: 2,
    "& .tool-tip": {
      display: "flex",
      width: "unset",
    },
  },
  bottom: {
    borderTop: `1px solid ${colorStone}`,
    marginTop: 20,
    paddingTop: 37,
    "& .gx-scorecard-list-header-comments": {
      marginLeft: 12,
      "& textarea": {
        height: 164,
        maxWidth: 600,
        width: "calc(100% - 12px)",
      },
    },
  },
  bottomShowAllComments: {
    paddingTop: 18,
  },
  buttonOptOut: {},
};

export default scoreListHeaderStyles;
