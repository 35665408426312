import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import { InitStatus, InitUserRole, ScoringMode, UUID } from "gx-npm-lib";
import { GartnerFooter, Loader, SnackbarBanner } from "gx-npm-ui";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useScoresheet from "./context/use-scoresheet.hook";
import useScoresheetLoad from "./context/load/use-scoresheet-load.hook";
import NoScoresViewComponent from "./sections/no-scores-view.component";
import ScoreContent from "./sections/score-content/score-content.component";
import styles from "./app.styles";

const useStyles = makeStyles(() => styles);
const App = () => {
  const { initProductId, initiativeId } = useParams();
  const [hasScrolled, setHasScrolled] = useState(false);
  const [isViewer, setIsViewer] = useState(false);
  const [sessionStorageId, setSessionStorageId] = useState("");
  const { isLoading, hasError, setHasError } = useScoresheetLoad(initiativeId as UUID, initProductId as UUID);
  const { initStatus, isEvaluator, userRole, scoringMode } = useScoresheet();
  const isInPreEvaluationStatus = scoringMode === ScoringMode.NONE || initStatus === InitStatus.PLANNING;

  useEffect(() => {
    const handleScroll = () => {
      setHasScrolled(window.scrollY > 0);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    setIsViewer(userRole === InitUserRole.VIEWER);
  }, [userRole]);

  useEffect(() => {
    if (initProductId) {
      setSessionStorageId(`workspace-scorecard-expand-collapse-${initProductId}`);
    }
  }, [initProductId]);

  const classes = useStyles();
  return (
    <div className={classNames(classes.appRoot)}>
      {isLoading && <Loader size={48} />}
      {!isLoading && isInPreEvaluationStatus && <NoScoresViewComponent />}
      {!isLoading && !isInPreEvaluationStatus && (
        <ScoreContent
          hasScrolled={hasScrolled}
          isEvaluator={isEvaluator}
          isViewer={isViewer}
          sessionStorageId={sessionStorageId}
        />
      )}
      {!isLoading && <GartnerFooter />}
      <SnackbarBanner isDefaultErrorMessage={true} isOpen={hasError} setIsOpen={setHasError} />
    </div>
  );
};
export default App;
