import { useScoresheetContext } from "../scoresheet.context";
import { UUID } from "gx-npm-lib";
import { useEffect, useState } from "react";
import {
  CategoryScoreConfigType,
  CustomEvents,
  ScoresheetRequirementsCatDto,
} from "../../sections/score-content/score-content.types";
import { saveCategoryScore, saveCategoryScoreAllOptOut } from "./category-score.lib";
import { updateEditCount } from "../lib";

const useReqCategoryScore = (catId: UUID) => {
  const {
    initiativeId,
    initProductId,
    categories,
    setCategories,
    setOverallScore,
    categorySavingCount,
    scoresheetEditCount,
    setScoresheetEditCount,
  } = useScoresheetContext();
  const [score, setScore] = useState<number>(0);
  const [savingCount, setSavingCount] = useState<number>(0);

  useEffect(() => {
    const category = categories.find((req) => req.id === catId);
    if (category) {
      setScore(category.score);
      setSavingCount(categorySavingCount[catId]);
    }
  }, [catId, categories, categorySavingCount]);

  useEffect(() => {
    window.dispatchEvent(
      new CustomEvent(CustomEvents.SCORESHEET_SAVING_COUNT, { detail: { count: scoresheetEditCount } })
    );
  }, [scoresheetEditCount]);

  const onCategoryScoreSuccess = (newCategories: ScoresheetRequirementsCatDto[], overallScore: number) => {
    setCategories(newCategories);
    setOverallScore(overallScore);
  };

  const onScoreAllOptOutSuccess = (newCategories: ScoresheetRequirementsCatDto[], overallScore: number) => {
    setCategories(newCategories);
    setOverallScore(overallScore);
  };
  const handleChangeCategoryScore = async (
    config: CategoryScoreConfigType,
    onError: (error: string | object) => void = (_error) => {
      /*do nothing*/
    }
  ) => {
    const { value } = config;
    const data = { score: value };
    const saveCategoryScoreConfig = { ...config, catId, initiativeId, initProductId, categories };
    updateEditCount(setScoresheetEditCount, 1);
    await saveCategoryScore(saveCategoryScoreConfig, data, onCategoryScoreSuccess, onError).finally(() =>
      updateEditCount(setScoresheetEditCount, -1)
    );
  };

  const handleChangeScoreAllOptOut = async (
    config: CategoryScoreConfigType,
    onError: (error: string | object) => void = (_error) => {
      /*do nothing*/
    }
  ) => {
    const { value } = config;
    const data = { score: value };
    const saveCategoryScoreConfig = { ...config, catId, initiativeId, initProductId, categories };
    await saveCategoryScoreAllOptOut(saveCategoryScoreConfig, data, onScoreAllOptOutSuccess, onError);
  };

  return { savingCount, score, handleChangeCategoryScore, handleChangeScoreAllOptOut };
};

export default useReqCategoryScore;
