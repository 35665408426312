// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CTt50aTT7nzSbgi2SH_l{margin-bottom:12px}.NtIx5IZvD7Ouu9Cq9RyK .d84MxPHlko3mbFBBI8gJ{padding-bottom:40px;padding-inline-start:24px}.iQx5vxTJsY3JbJcJNAro{float:right}`, "",{"version":3,"sources":["webpack://./../gx-npm-ui/src/free-trial/free-trial-info-modal/free-trial-info-modal.styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,kBAAA,CAGF,4CACE,mBAAA,CACA,yBAAA,CAGF,sBACE,WAAA","sourcesContent":[".sentenceBottomBreak {\n  margin-bottom: 12px;\n}\n\n.listContainer .list {\n  padding-bottom: 40px;\n  padding-inline-start: 24px;\n}\n\n.footerButton {\n  float: right;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sentenceBottomBreak": `CTt50aTT7nzSbgi2SH_l`,
	"listContainer": `NtIx5IZvD7Ouu9Cq9RyK`,
	"list": `d84MxPHlko3mbFBBI8gJ`,
	"footerButton": `iQx5vxTJsY3JbJcJNAro`
};
export default ___CSS_LOADER_EXPORT___;
