import { isValidResponse, UUID } from "gx-npm-lib";
import {
  CategoryCommentData,
  CommentType,
  ScoresheetRequirementsCatDto,
} from "../../sections/score-content/score-content.types";
import { postRequest, putRequest } from "../requests";

const saveCategoryComment = async (
  config: {
    initiativeId: UUID;
    initProductId: UUID;
    catId: UUID;
    categories: ScoresheetRequirementsCatDto[];
    value: string;
    commentId: UUID;
  },
  categoryCommentData: CategoryCommentData,
  onSuccessResponse: (newRequirements: ScoresheetRequirementsCatDto[]) => void,
  onErrorResponse: (error: string | object) => void
) => {
  const { value, commentId, initiativeId, initProductId, categories, catId } = config;
  const userExtension = "/user";
  const urlEnd = commentId ? `/${commentId}${userExtension}` : userExtension;
  const url = `/api/v2/initiatives/${initiativeId}/products/${initProductId}/comments/${CommentType.SCORECARD_REQ_CAT}${urlEnd}`;
  const asyncReq = commentId ? putRequest : postRequest;
  const response = await asyncReq(url, categoryCommentData);
  if (isValidResponse(response)) {
    const newCategories = categories.map((req) => {
      if (req.id === catId) {
        if (commentId) {
          return { ...req, reqCategoryComment: { id: req.reqCategoryComment.id, description: value } };
        } else {
          return { ...req, reqCategoryComment: { id: response.data.data.id, description: value } };
        }
      }
      return req;
    });
    onSuccessResponse(newCategories);
  } else {
    onErrorResponse(response);
  }
};

export { saveCategoryComment };
