import { colorPalette } from "gx-npm-common-styles";

const appStyles = {
  appRoot: {
    backgroundColor: `var(--${colorPalette.neutrals.quartz.name})`,
    height: "100vh",
    minWidth: "1024px",
    "& .gx-base-textarea textarea": {
      // when not using maxRows, this is needed to support scroll
      overflowY: "auto !important",
      resize: "vertical",
      "&:nth-child(2)": {
        // safari
        resize: "none",
      },
    },
  },
};

export default appStyles;
