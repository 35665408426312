import React from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { formatDecimalToString } from "gx-npm-lib";
import { SpinningNumber } from "../../../../../../../ui/spinningNumber";
import styles from "./requirement-score-pill.styles";
import { TypographyComponent } from "gx-npm-ui";

const useStyles = makeStyles(() => styles);
const RequirementScorePill = ({ isOptedOutAll = false, isProcessing = false, rootClassName = "", score = 0 }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <div className={classNames("semi-bold", classes.categoryScore, rootClassName)}>
      <TypographyComponent
        styling={"p3"}
        boldness={"medium"}
        color={"carbon"}
        rootClassName={classNames(classes.categoryScoreName)}
      >
        {isOptedOutAll ? t("Opted out") : t("My category score ")}
      </TypographyComponent>

      {!isOptedOutAll && <SpinningNumber isSpinning={isProcessing} value={formatDecimalToString(score, 0, 2)} />}
    </div>
  );
};

export default RequirementScorePill;
