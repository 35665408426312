import { isValidResponse, UUID } from "gx-npm-lib";
import { CategoryScoreData, ScoresheetRequirementsCatDto } from "../../sections/score-content/score-content.types";
import { postRequest } from "../requests";
const saveCategoryScore = async (
  config: {
    initiativeId: UUID;
    initProductId: UUID;
    catId: UUID;
    categories: ScoresheetRequirementsCatDto[];
    value: number;
  },
  categoryScoreData: CategoryScoreData,
  onSuccessResponse: (newRequirements: ScoresheetRequirementsCatDto[], overallScore: number) => void,
  onErrorResponse: (error: string | object) => void
) => {
  const { value, initiativeId, initProductId, categories, catId } = config;
  const url = `/api/v2/initiatives/${initiativeId}/products/${initProductId}/scorecard/category/${catId}/user`;
  const response = await postRequest(url, categoryScoreData);
  if (isValidResponse(response)) {
    const newCategories = categories.map((category) => {
      if (category.id === catId) {
        return { ...category, score: value };
      }
      return category;
    });
    onSuccessResponse(newCategories, response.data.data.overallScore);
  } else {
    onErrorResponse(response);
  }
};

const saveCategoryScoreAllOptOut = async (
  config: {
    initiativeId: UUID;
    initProductId: UUID;
    catId: UUID;
    categories: ScoresheetRequirementsCatDto[];
    value: number;
  },
  categoryScoreData: CategoryScoreData,
  onSuccessResponse: (newRequirements: ScoresheetRequirementsCatDto[], overallScore: number) => void,
  onErrorResponse: (error: string | object) => void
) => {
  const { value, initiativeId, initProductId, categories, catId } = config;
  const url = `/api/v2/initiatives/${initiativeId}/products/${initProductId}/scorecard/category/${catId}/bulk-update/user`;
  const response = await postRequest(url, categoryScoreData);
  if (isValidResponse(response)) {
    const newCategories = categories.map((category) => {
      if (category.id === catId) {
        const newItems = category.items.map((item) => {
          return { ...item, score: value };
        });
        return { ...category, items: newItems, score: response.data.data.categoryScore };
      }
      return category;
    });
    onSuccessResponse(newCategories, response.data.data.overallScore);
  } else {
    onErrorResponse(response);
  }
};

export { saveCategoryScore, saveCategoryScoreAllOptOut };
