import React, { Fragment, useState } from "react";
import RequirementScoreRow from "./requirement-score-row/requirement-score-row.component";
import useReqItemComments from "../../../../../../context/item-comments/use-req-item-comments.hook";
import useReqItemScore from "../../../../../../context/item-score/use-req-item-score.hook";
import useRequirements from "../../../../../../context/use-requirements.hook";
import { reqComm } from "../../../../../../context/lib";
import { SnackbarBanner } from "gx-npm-ui";
import { ScoringLevel, UUID } from "gx-npm-lib";

type ScoreListBodyItemProps = {
  catId: UUID;
  description: string;
  isViewOnly?: boolean;
  name: string;
  priority: number;
  reqId: UUID;
};
const ScoreListBodyItem: React.FC<ScoreListBodyItemProps> = ({
  catId,
  description,
  isViewOnly = false,
  name,
  priority,
  reqId,
}) => {
  const { commentDesc, commentId, handleChangeItemComments } = useReqItemComments(catId, reqId);
  const { score, handleChangeItemScore } = useReqItemScore(catId, reqId);
  const { scoringLevel } = useRequirements();
  const [isSaveError, setIsSaveError] = useState(false);
  const handleSave = (key: string, value: number | string) => {
    const onError = () => {
      setIsSaveError(true);
      setTimeout(() => setIsSaveError(false), 3000);
    };
    if (key === reqComm) {
      handleChangeItemComments({ value: value as string, commentId }, onError);
    } else {
      handleChangeItemScore({ value: value as number }, onError);
    }
  };

  return (
    <Fragment>
      <RequirementScoreRow
        comments={commentDesc}
        description={description}
        isScoringLevelItem={scoringLevel === ScoringLevel.REQUIREMENT}
        isViewOnly={isViewOnly}
        name={name}
        onSave={handleSave}
        priority={priority}
        score={score}
      />
      <SnackbarBanner isDefaultErrorMessage={true} isOpen={isSaveError} />
    </Fragment>
  );
};

export default ScoreListBodyItem;
