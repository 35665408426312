import { isValidNumber } from "../isUtils";

/**
 * @param {*} value number value to be formatted
 * @param {*} precision number of decimal points to format, defaults to 0
 * @param {*} magnitude order of magnitude to multiply number (10^n), defaults to 0
 * @param {*} defaultToZero set value to 0 if NaN value is provided, defaults to true
 * @returns formatted decimal
 */
const formatDecimalToString = (
  value,
  precision = 0,
  magnitude = 0,
  defaultToZero = true
) => {
  let formatted = "";
  if (isValidNumber(value) || defaultToZero) {
    formatted = isValidNumber(value) ? value : 0;
    const calcMag = isValidNumber(magnitude) ? magnitude : 0;
    formatted = parseFloat(formatted) * Math.pow(10, calcMag);
    const usedPrecision = precision > -1 ? precision : 0;
    formatted = parseFloat(formatted).toFixed(usedPrecision);
  }
  return formatted + "";
};

export default formatDecimalToString;
