import React, { Fragment, useEffect, useState } from "react";
import { optOut } from "../../../../../../lib";
import RequirementScoreHeader from "./requirement-score-header/requirement-score-header.component";
import useReqCategoryComments from "../../../../../../context/category-comments/use-req-category-comments.hook";
import useReqCategoryScore from "../../../../../../context/category-score/use-req-category-score.hook";
import useRequirements from "../../../../../../context/use-requirements.hook";
import { SnackbarBanner } from "gx-npm-ui";
import { ScoringLevel, UUID } from "gx-npm-lib";

// NOTE - expand and setExpand are injected into header through clone process
type ScoreListHeaderProps = {
  catId: UUID;
  expand?: boolean;
  isViewOnly?: boolean;
  name: string;
  setExpand?: (val: boolean) => void;
};

const ScoreListHeader: React.FC<ScoreListHeaderProps> = ({
  catId,
  expand = false,
  isViewOnly = false,
  name,
  setExpand = () => {},
}) => {
  const { commentId, commentDesc, handleChangeCategoryComments } = useReqCategoryComments(catId);
  const { handleChangeCategoryScore, handleChangeScoreAllOptOut, savingCount, score } = useReqCategoryScore(catId);
  const { categories, scoringLevel } = useRequirements();
  const [isSaveError, setIsSaveError] = useState(false);
  const [isOptedOutAll, setIsOptedOutAll] = useState(false);

  useEffect(() => {
    const reqCategory = categories.find((req) => req.id === catId);
    if (!reqCategory || reqCategory.items.length < 1) {
      return;
    }
    let reqScoreCount = 0;
    let reqOptOutCount = 0;
    reqCategory.items.forEach((item) => {
      const thisReqScore = item.score;
      if (thisReqScore === optOut.score) {
        reqOptOutCount = reqOptOutCount + 1;
      }
      reqScoreCount = reqScoreCount + 1;
    });
    setIsOptedOutAll(reqScoreCount === reqOptOutCount);
  }, [catId, categories, score]);

  const handleOptOutAll = () => {
    const onError = () => {
      setIsSaveError(true);
      setTimeout(() => setIsSaveError(false), 3000);
    };

    handleChangeScoreAllOptOut({ value: optOut.score }, onError);
  };

  const handleSaveComment = (value: string) => {
    const onError = () => {
      setIsSaveError(true);
      setTimeout(() => setIsSaveError(false), 3000);
    };

    handleChangeCategoryComments({ value, commentId }, onError);
  };

  const handleSaveScore = (value: number) => {
    const onError = () => {
      setIsSaveError(true);
      setTimeout(() => setIsSaveError(false), 3000);
    };
    handleChangeCategoryScore({ value }, onError);
  };
  return (
    <Fragment>
      <RequirementScoreHeader
        comments={commentDesc}
        headerExpand={expand}
        isOptedOutAll={isOptedOutAll}
        isScoringLevelCategory={scoringLevel === ScoringLevel.CATEGORY}
        isProcessing={!!savingCount}
        isViewOnly={isViewOnly}
        name={name}
        onOptOut={handleOptOutAll}
        onSaveComment={handleSaveComment}
        onSaveScore={handleSaveScore}
        score={score}
        headerSetExpand={setExpand}
      />
      <SnackbarBanner isDefaultErrorMessage={true} isOpen={isSaveError} />
    </Fragment>
  );
};

export default ScoreListHeader;
