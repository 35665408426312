import React from "react";
import { messengerFlagLoader } from "gx-npm-lib";
import { FeatureFlagProvider } from "gx-npm-ui";
import { createGenerateClassName, StylesProvider } from "@material-ui/core/styles";
import App from "./app.component";
import { ScoresheetContextProvider } from "./context/scoresheet.context";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HandleRedirects from "./handle-redirects";

const generateClassName = createGenerateClassName({
  seed: "Scoresheet-App",
});
const Root = () => {
  return (
    <FeatureFlagProvider loader={messengerFlagLoader()}>
      <StylesProvider generateClassName={generateClassName}>
        <ScoresheetContextProvider>
          <BrowserRouter>
            <Routes>
              <Route path={"/s/evaluation/:initiativeId/product/:initProductId/scoresheet"} element={<App />} />
              <Route path={"*"} element={<HandleRedirects />} />
            </Routes>
          </BrowserRouter>
        </ScoresheetContextProvider>
      </StylesProvider>
    </FeatureFlagProvider>
  );
};

export default Root;
