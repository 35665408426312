import { createContext, ReactNode, useContext, useState } from "react";
import { ScorecardFormContextValue } from "../scorecard-form.types";

const ScoreFormContext = createContext<ScorecardFormContextValue | null>(null);

const useScoreFormContext = () => {
  const displayDescCommentsContext = useContext(ScoreFormContext);

  if (displayDescCommentsContext === null) {
    throw new Error("useScoreFormContext must be inside a ScoreFormContextProvider");
  }
  return displayDescCommentsContext;
};

const ScoreFormContextProvider = (props: { children: ReactNode | Array<ReactNode> }) => {
  const [showAllComments, setShowAllComments] = useState(false);
  const [showAllDescriptions, setShowAllDescriptions] = useState(true);

  const contextValue = {
    showAllComments,
    setShowAllComments,
    showAllDescriptions,
    setShowAllDescriptions,
  };

  return <ScoreFormContext.Provider value={contextValue}>{props.children}</ScoreFormContext.Provider>;
};

export { ScoreFormContext, ScoreFormContextProvider, useScoreFormContext };
