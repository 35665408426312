import { useEffect, useState } from "react";
import { useScoresheetContext } from "../scoresheet.context";
import { useUserInitAccess, UUID } from "gx-npm-lib";
import { loadScoresheetData } from "./scoresheet-load.lib";
import { ScoresheetResType } from "../../sections/score-content/score-content.types";

export type ScoresheetLoad = {
  hasError: boolean;
  isLoading: boolean;
  setHasError: (value: boolean) => void;
};
const useScoresheetLoad = (initId: UUID, initProductId: UUID): ScoresheetLoad => {
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingRole, setIsLoadingUser] = useState(true);
  const [isLoadingScoresheet, setIsLoadingScoresheet] = useState(true);
  const { hasLoadedAccess, role } = useUserInitAccess(initId);

  const {
    setCategorySavingCount,
    setKeyTakeawaysDesc,
    setKeyTakeawaysId,
    setOverallScore,
    setInitiativeId,
    setInitProductId,
    setInitStatus,
    setIsEvaluator,
    setCategories,
    setScoringLevel,
    setScoringMode,
    setScorecardProgress,
    setTotalAssignedCategories,
    setTotalCategories,
    setUserRole,
    setProductState,
  } = useScoresheetContext();

  useEffect(() => {
    const handleLoadData = (payload: ScoresheetResType) => {
      setInitProductId(initProductId);
      setInitiativeId(initId);
      setInitStatus(payload.initStatus);
      setIsEvaluator(payload.isEvaluator);
      setCategorySavingCount(
        payload.scoresheet.categories.reduce(
          (acc, curr) => {
            acc[curr.id] = 0;
            return acc;
          },
          {} as Record<UUID, number>
        )
      );
      setKeyTakeawaysDesc(payload.scoresheet.keyTakeawaysComment.description);
      setKeyTakeawaysId(payload.scoresheet.keyTakeawaysComment.id);
      setOverallScore(payload.scoresheet.overallScore);
      setCategories(payload.scoresheet.categories);
      setScoringLevel(payload.scoresheet.scoringLevel);
      setScoringMode(payload.scoringMode);
      setScorecardProgress(payload.scoresheet.scorecardProgress);
      setTotalAssignedCategories(payload.scoresheet.totalAssignedCategories);
      setTotalCategories(payload.scoresheet.totalCategories);
      setProductState(payload.productState);
    };

    if (!initId || !initProductId) {
      return;
    }
    setIsLoadingScoresheet(true);
    const loadData = async () => {
      await loadScoresheetData({ initId, initProductId }, handleLoadData, () => setHasError(true));
    };

    loadData().finally(() => setIsLoadingScoresheet(false));
  }, [
    initId,
    initProductId,
    setCategories,
    setCategorySavingCount,
    setInitProductId,
    setInitiativeId,
    setInitStatus,
    setIsEvaluator,
    setKeyTakeawaysDesc,
    setKeyTakeawaysId,
    setOverallScore,
    setProductState,
    setScorecardProgress,
    setScoringLevel,
    setScoringMode,
    setTotalAssignedCategories,
    setTotalCategories,
  ]);

  useEffect(() => {
    if (!hasLoadedAccess) {
      setIsLoadingUser(true);
      return;
    }
    setIsLoadingUser(false);
    setUserRole(role);
  }, [hasLoadedAccess, role, setUserRole]);

  useEffect(() => {
    setIsLoading(isLoadingScoresheet || isLoadingRole);
  }, [isLoadingScoresheet, isLoadingRole]);

  return { hasError, isLoading, setHasError };
};

export default useScoresheetLoad;
