import { Dispatch, SetStateAction } from "react";
import {
  InitProdState,
  InitStatus,
  InitUserRole,
  ScorecardProgress,
  ScoringLevel,
  ScoringMode,
  UUID,
} from "gx-npm-lib";

export type ScoresheetContextValue = {
  initiativeId: UUID;
  initProductId: UUID;
  initStatus: InitStatus | "";
  isEvaluator: boolean;
  categorySavingCount: CategorySavingCountType;
  keyTakeawaysDesc: string;
  keyTakeawaysId: UUID | "";
  overallScore: number;
  totalAssignedCategories: number;
  totalCategories: number;
  categories: ScoresheetRequirementsCatDto[];
  scoresheetEditCount: number;
  scoringLevel: ScoringLevel | "";
  scoringMode: ScoringMode | "";
  scorecardProgress: ScorecardProgress | "";
  userRole: InitUserRole | "";
  productState: InitProdState | "";
  setInitiativeId: Dispatch<SetStateAction<UUID>>;
  setInitProductId: Dispatch<SetStateAction<UUID>>;
  setInitStatus: Dispatch<SetStateAction<InitStatus | "">>;
  setIsEvaluator: Dispatch<SetStateAction<boolean>>;
  setCategorySavingCount: Dispatch<SetStateAction<CategorySavingCountType>>;
  setKeyTakeawaysDesc: Dispatch<SetStateAction<string>>;
  setKeyTakeawaysId: Dispatch<SetStateAction<UUID | "">>;
  setOverallScore: Dispatch<SetStateAction<number>>;
  setCategories: Dispatch<SetStateAction<ScoresheetRequirementsCatDto[]>>;
  setScoresheetEditCount: Dispatch<SetStateAction<number>>;
  setTotalAssignedCategories: Dispatch<SetStateAction<number>>;
  setTotalCategories: Dispatch<SetStateAction<number>>;
  setScoringLevel: Dispatch<SetStateAction<ScoringLevel | "">>;
  setScoringMode: Dispatch<SetStateAction<ScoringMode | "">>;
  setScorecardProgress: Dispatch<SetStateAction<ScorecardProgress | "">>;
  setUserRole: Dispatch<SetStateAction<InitUserRole | "">>;
  setProductState: Dispatch<SetStateAction<InitProdState | "">>;
};

export type CategorySavingCountType = Record<UUID, number>;
export type ScoresheetReqType = {
  initId: UUID;
  initProductId: UUID;
};

export type ScoresheetResType = {
  initStatus: InitStatus;
  isEvaluator: boolean;
  productState: InitProdState;
  scoresheet: UserScoresheetDto;
  scoringMode: ScoringMode;
};

type UserScoresheetDto = {
  keyTakeawaysComment: {
    id: UUID | "";
    description: string;
  };
  categories: ScoresheetRequirementsCatDto[];
  scoringLevel: ScoringLevel;
  totalAssignedCategories: number;
  totalCategories: number;
  overallScore: number;
  scorecardProgress: ScorecardProgress;
};

export type ScoresheetRequirementsCatDto = {
  id: UUID;
  name: string;
  reqCategoryComment: {
    id: UUID | "";
    description: string;
  };
  score: number;
  items: ScoresheetRequirementsItemDto[];
  weight: number;
};

export type ScoresheetRequirementsItemDto = {
  reqItemComment: {
    id: UUID | "";
    description: string;
  };
  id: UUID;
  name: string;
  description: string;
  priority: number;
  score: number;
};

export enum CustomEvents {
  SCORESHEET_SAVING_COUNT = "SCORESHEET_SAVING_COUNT",
}

export enum CommentType {
  SCORECARD_KEY_TAKEAWAYS = "SCORECARD_KEY_TAKEAWAYS",
  SCORECARD_REQ_CAT = "SCORECARD_REQ_CAT",
  SCORECARD_REQ_ITEM = "SCORECARD_REQ_ITEM",
}

export type CategoryCommentConfigType = {
  value: string;
  commentId: UUID | "";
};

export type CategoryCommentData = {
  description: string;
  parentId?: UUID;
};

export type CategoryScoreConfigType = {
  value: number;
};

export type CategoryScoreData = {
  score: number;
};

export type KeyTakeawaysConfigType = {
  value: string;
};

export type KeyTakeawaysData = {
  description: string;
};

export type ItemCommentConfigType = {
  value: string;
  commentId: UUID | "";
};

export type ItemCommentData = {
  description: string;
  parentId?: UUID;
};

export type ItemScoreConfigType = {
  value: number;
};

export type ItemScoreData = {
  score: number;
};
