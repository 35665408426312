import { colorPalette } from "gx-npm-common-styles";

const messageIconStyles = {
  messageIconWrapper: {
    "&:hover": {
      "& svg path": {
        fill: colorPalette.neutrals.coal.hex,
      },
    },
  },
  "@global": {
    ".gx-survey-message-tool-tip": {
      left: "-7px !important",
    },
  },
};

export { messageIconStyles };
