import { ScoresheetReqType } from "../../sections/score-content/score-content.types";
import { InitProdState, isValidResponse, ScoringMode } from "gx-npm-lib";
import { getRequest } from "../requests";

const loadScoresheetData = async (
  scoresheetReq: ScoresheetReqType,
  onSuccessResponse: (data: object) => void,
  onErrorResponse: (error: string | object) => void
) => {
  const { initId, initProductId } = scoresheetReq;
  const url = `/api/v2/initiatives/${initId}/products/${initProductId}/user-scoresheet`;
  const response = await getRequest(url);
  if (isValidResponse(response) && typeof response.data.data === "object") {
    const { data } = response.data;
    onSuccessResponse({
      initStatus: data.initStatus,
      isEvaluator: data.isEvaluator || false,
      productState: data.productState || InitProdState.IN_EVALUATION,
      scoresheet: data.scoresheet || {},
      scoringMode: data.scoringMode || ScoringMode.NONE,
    });
  } else {
    onErrorResponse(response);
  }
};

export { loadScoresheetData };
