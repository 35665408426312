import { makeStyles } from "@material-ui/core/styles";
import { getStoredJsonItem, setStoredJsonItem, UUID } from "gx-npm-lib";
import { NotifyWhenStickyDetachV2Component } from "gx-npm-ui";
import { useCallback, useEffect, useRef, useState } from "react";
import List from "../../../../../ui/list/list.component";
import { ListItemsContainer, ListItem } from "../../../../../ui/list/body";
import { ScoresheetRequirementsItemDto } from "../../../score-content.types";
import ScoreListHeader from "./score-list-header/score-list-header.component";
import ScoreListBodyItem from "./score-list-body-item/score-list-body-item.component";
import styles from "./requirement-score-list.styles";

const PAGE_HEADER_HEIGHT_IN_PX = 128;
type RequirementScoreListProps = {
  catId: UUID;
  catName: string;
  index: number;
  isViewOnly: boolean;
  requirementsList: ScoresheetRequirementsItemDto[];
  sessionStorageId: string;
  totalCategories: number;
};
const useStyles = makeStyles(() => styles);
const RequirementScoreList = ({
  catId = "",
  catName = "",
  index = -1,
  isViewOnly = false,
  requirementsList = [],
  sessionStorageId = "",
  totalCategories = -1,
}: RequirementScoreListProps) => {
  const classes = useStyles();
  const contentRef = useRef<HTMLDivElement>(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isLoadSessionData, setIsLoadSessionData] = useState(false);

  const handleExpandState = useCallback(
    (val) => {
      const data = getStoredJsonItem(sessionStorageId);
      setIsExpanded(val);
      if (data && totalCategories > 100) {
        for (const id of Object.keys(data)) {
          data[id] = id === catId ? val : false;
        }
      }
      setStoredJsonItem(sessionStorageId, { ...data, [catId]: val });
    },
    [catId, totalCategories, sessionStorageId]
  );

  useEffect(() => {
    if (!sessionStorageId || !catId) {
      return;
    }
    const sessionData = getStoredJsonItem(sessionStorageId);
    const expandState = sessionData?.[catId];
    setIsExpanded(expandState);
    setIsLoadSessionData(true);
    if (index === 0 && !sessionData) {
      handleExpandState(true);
    }
  }, [catId, handleExpandState, index, sessionStorageId]);

  if (!isLoadSessionData) {
    return null;
  }

  return (
    <NotifyWhenStickyDetachV2Component
      dependencyData={[isExpanded]}
      reference={contentRef}
      topOffsetInPx={PAGE_HEADER_HEIGHT_IN_PX}
    >
      <div className={classes.content} ref={contentRef}>
        <List defaultExpand={isExpanded || false} handleExpand={handleExpandState}>
          <ScoreListHeader catId={catId} isViewOnly={isViewOnly} name={catName} />
          <ListItemsContainer>
            {isExpanded &&
              requirementsList?.map((item, idx) => {
                const { description, id, name, priority } = item;
                return (
                  <ListItem key={idx} rootClassName="gx-scorecard-list-item">
                    <ScoreListBodyItem
                      catId={catId}
                      description={description}
                      isViewOnly={isViewOnly}
                      name={name}
                      priority={priority}
                      reqId={id}
                    />
                  </ListItem>
                );
              })}
          </ListItemsContainer>
        </List>
      </div>
    </NotifyWhenStickyDetachV2Component>
  );
};

export default RequirementScoreList;
