import React, { useState } from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core";
import { getListHeaderStyles } from "./styles";
import { STICKY_HEADER_IGNORE_CLASS, STICKY_HEADER_WRAPPER_CLASS } from "gx-npm-ui";

type ListHeaderProps = {
  children?: React.ReactNode;
  expand?: boolean;
  index?: number;
  rootClassName?: string;
  setExpand?: (val: boolean) => void;
  sticky?: number;
  renderItem: (expand: boolean | undefined, setExpand: ((val: boolean) => void) | undefined) => React.ReactNode;
};
const ListHeader: React.FC<ListHeaderProps> = ({
  expand = false,
  index = 0,
  rootClassName = "",
  setExpand = () => {},
  sticky,
  renderItem,
}) => {
  const [isHovering, setIsHovering] = useState(false);
  const listHeaderStyles = getListHeaderStyles(sticky, index);
  const useStyles = makeStyles(() => listHeaderStyles);
  const classes = useStyles();

  const handleMouseEvent = (type: string) => {
    setIsHovering("enter" === type || "move" === type);
  };

  return (
    <section
      className={classNames(
        "gx-list-header",
        "list-header",
        rootClassName,
        classes.listHeader,
        expand ? "expand" : "collapse",
        !expand && STICKY_HEADER_IGNORE_CLASS,
        sticky && STICKY_HEADER_WRAPPER_CLASS,
        isHovering && "hover"
      )}
      onBlur={() => handleMouseEvent("blur")}
      onMouseEnter={() => handleMouseEvent("enter")}
      onMouseMove={() => handleMouseEvent("move")}
      onMouseOut={() => handleMouseEvent("out")}
    >
      {renderItem(expand, setExpand)}
    </section>
  );
};

export default ListHeader;
