// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Xt5bZu6mkNvGVmW8hpgW{background-color:var(--color-white);border-radius:8px;margin-bottom:12px;width:345px}.u9bIb8YLy9ptTeGnfZNh{align-items:center;display:flex;padding:12px 24px 12px 12px}.zWRSiD2uasCScspPE1it{margin-left:8px}.dmM8BbvqoJcl0LWRmwaM{padding:0 24px 24px 24px}.dmM8BbvqoJcl0LWRmwaM textarea{max-height:calc(100vh - 416px);min-height:45px;width:100%}.dmM8BbvqoJcl0LWRmwaM textarea::placeholder{color:var(--color-basic-text-secondary)}`, "",{"version":3,"sources":["webpack://./src/sections/score-content/components/sidebar-comment-v2/sidebar-comment-v2.styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,mCAAA,CACA,iBAAA,CACA,kBAAA,CACA,WAAA,CAGF,sBACE,kBAAA,CACA,YAAA,CACA,2BAAA,CAGF,sBACE,eAAA,CAGF,sBACE,wBAAA,CAEA,+BACE,8BAAA,CACA,eAAA,CACA,UAAA,CAEA,4CACE,uCAAA","sourcesContent":[".commentWrapper {\n  background-color: var(--color-white);\n  border-radius: 8px;\n  margin-bottom: 12px;\n  width: 345px;\n}\n\n.commentHeader {\n  align-items: center;\n  display: flex;\n  padding: 12px 24px 12px 12px;\n}\n\n.headingText {\n  margin-left: 8px;\n}\n\n.inputWrapper {\n  padding: 0 24px 24px 24px;\n\n  & textarea {\n    max-height: calc(100vh - 416px);\n    min-height: 45px;\n    width: 100%;\n\n    &::placeholder {\n      color: var(--color-basic-text-secondary);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"commentWrapper": `Xt5bZu6mkNvGVmW8hpgW`,
	"commentHeader": `u9bIb8YLy9ptTeGnfZNh`,
	"headingText": `zWRSiD2uasCScspPE1it`,
	"inputWrapper": `dmM8BbvqoJcl0LWRmwaM`
};
export default ___CSS_LOADER_EXPORT___;
