import { ScoresheetRequirementsCatDto } from "../sections/score-content/score-content.types";
import { ScoringLevel, UUID } from "gx-npm-lib";
import { Dispatch, SetStateAction } from "react";

const calculateScorecardProgressV2 = (scoringLevel: ScoringLevel | "", categories: ScoresheetRequirementsCatDto[]) => {
  const catKeys = categories.map((req) => req.id);
  return calculateScorecardProgressFromCatIdKeys(categories, scoringLevel, catKeys);
};

const calculateScorecardProgressFromCatIdKeys = (
  categories: ScoresheetRequirementsCatDto[],
  scoringLevel: ScoringLevel | "",
  reqCatIds: UUID[]
) => {
  let cnt = 0;
  let total = 0;
  let totalCats = 0;

  if (scoringLevel === ScoringLevel.REQUIREMENT) {
    categories.forEach((category) => {
      category.items.forEach((item) => {
        if (item.score) {
          cnt++;
        }
        total++;
      });
      totalCats++;
    });
  } else if (scoringLevel === ScoringLevel.CATEGORY) {
    total = reqCatIds.length;
    categories.forEach((category) => {
      if (category.score) {
        cnt++;
      }
      totalCats++;
    });
  }

  return {
    total: total,
    cnt: cnt,
    totalCats: totalCats,
  };
};

const updateEditCount = (setEditCount: Dispatch<SetStateAction<number>>, value: number) => {
  setEditCount((prev) => prev + value);
};

const reqComm = "comments";
const reqScore = "requirementScore";

export { calculateScorecardProgressV2, reqComm, reqScore, updateEditCount };
