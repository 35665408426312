import React from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core";
import { ArrowCircledIcon } from "gx-npm-icons";
import { handleEvent } from "gx-npm-lib";
import { ToolTipIconButton } from "gx-npm-ui";
import { listHeaderExpandButtonStyles as styles } from "./styles";

type ListHeaderExpandButtonProps = {
  expand?: boolean;
  rootClassName?: string;
  setExpand?: (val: boolean) => void;
  expandLabel?: string;
  collapseLabel?: string;
};
const useStyles = makeStyles(() => styles);
const ListHeaderExpandButton: React.FC<ListHeaderExpandButtonProps> = ({
  expand = false,
  rootClassName = "",
  setExpand = () => {},
  expandLabel = "",
  collapseLabel = "",
}) => {
  const classes = useStyles();
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    handleEvent(setExpand, !expand);
  };

  return (
    <div className={classNames("gx-exp-col-btn", classes.iconExpand, rootClassName, expand && "gx-is-expanded")}>
      <ToolTipIconButton onClick={handleClick} showTooltip={true} tooltipText={expand ? collapseLabel : expandLabel}>
        <ArrowCircledIcon />
      </ToolTipIconButton>
    </div>
  );
};

export default ListHeaderExpandButton;
