const optOut = {
  score: -1,
  internalScore: -2,
};

/**
 * gets score index. Since the popovermenu uses -1 as the index for nothing selected, this function allows using
 * another index such as -2 if -1 is returned from the back end for another use
 * @param {*} score index value to get
 * @param {boolean} reverse get the key
 * @returns a valid score index
 */

const scoreMenuItemsIndexes: Record<number, number> = {
  [optOut.score]: optOut.internalScore,
};

const getScoreIndex = (score: number, reverse = false): number => {
  if (!reverse) {
    return scoreMenuItemsIndexes[score] ? scoreMenuItemsIndexes[score] : score;
  } else {
    const reverseScore = Object.keys(scoreMenuItemsIndexes).find((key) => scoreMenuItemsIndexes[Number(key)] === score);
    return reverseScore ? Number(reverseScore) : score;
  }
};

export { getScoreIndex, optOut };
