import { getAsyncRequest, postAsyncRequest, putAsyncRequest } from "gx-npm-lib";

const getRequest = async (url: string) => {
  return await getAsyncRequest(url);
};
const postRequest = async (url: string, payload: object) => {
  return await postAsyncRequest(url, payload);
};
const putRequest = async (url: string, payload: object) => {
  return await putAsyncRequest(url, payload);
};

export { getRequest, postRequest, putRequest };
