import { colorPalette } from "gx-npm-common-styles";

const colorCarbon = colorPalette.neutrals.carbon.hex;

const scorePillStyles = {
  categoryScore: {
    backgroundColor: colorPalette.neutrals.quartz.hex,
    borderRadius: 50,
    color: colorCarbon,
    display: "inline-flex",
    marginLeft: "auto",
    marginRight: 20,
    paddingBottom: 14,
    paddingRight: 24,
    paddingLeft: 24,
    paddingTop: 14,
    "&.collaboration": {
      marginLeft: 0,
    },
    "@media (max-width:1024px)": {
      marginLeft: 55,
    },
  },
  categoryScoreName: {
    paddingRight: "4px",
  },
};

export default scorePillStyles;
