import React, { Fragment } from "react";
import RequirementScoreList from "./requirement-score-list/requirement-score-list.component";
import ScoreFormControls from "./score-form-controls/score-form-controls.component";
import { ScoreFormContextProvider } from "./context/score-form-context";
import useRequirements from "../../../../context/use-requirements.hook";

type ScorecardFormProps = {
  isViewOnly: boolean;
  sessionStorageId: string;
};

const ScorecardForm: React.FC<ScorecardFormProps> = ({ isViewOnly = false, sessionStorageId }) => {
  const { categories, totalCategories } = useRequirements();
  return (
    <Fragment>
      <ScoreFormContextProvider>
        {<ScoreFormControls />}
        {categories?.map((data, idx) => {
          const { id, items, name } = data;
          return (
            <RequirementScoreList
              catId={id}
              catName={name}
              index={idx}
              isViewOnly={isViewOnly}
              key={id || idx}
              requirementsList={items}
              sessionStorageId={sessionStorageId}
              totalCategories={totalCategories}
            />
          );
        })}
      </ScoreFormContextProvider>
    </Fragment>
  );
};

export default ScorecardForm;
