import { getScoreIndex, optOut } from "./getScoreIndex";

const headerHeights = {
  navigation: 72,
  preview: 72,
  vendorInfo: {
    mediaSmall: 180,
    mediaStandard: 96,
    mediaScrolled: 56,
  },
};

export { getScoreIndex, headerHeights, optOut };
