import { colorPalette } from "gx-npm-common-styles";
const mildBerrySixtyOpacityEquivalent = "#CEDDF6";
const scoreFormControlsStyles = {
  buttonsWrapper: {
    marginBottom: "16px",
    display: "flex",
    "& .slide-out": {
      transform: "translateX(115%)",
    },
    "& .gx-score-form-controls-icon-button": {
      "&.not-selected": {
        background: colorPalette.neutrals.quartz.hex,
      },
      "&.selected": {
        background: mildBerrySixtyOpacityEquivalent,
      },
    },
  },
  hideShowButtons: {
    alignItems: "center",
    borderRadius: "8px 0 0 8px",
    backgroundColor: colorPalette.basic.white.hex,
    display: "flex",
    transition: "transform 0.6s ease",
    "& .gx-hide-all-description-button": {
      margin: "8px 19px 8px 20px",
    },
    "& .gx-show-all-description-button": { margin: "8px 19px 8px 20px" },
    "& .gx-show-comments-button": {
      margin: "8px 16px 8px 16px",
    },
    "& .gx-show-all-comments-button": {
      margin: "8px 16px 8px 16px",
    },
  },

  verticalLine: {
    width: "2px",
    height: "100%",
    backgroundColor: colorPalette.basic.gray.hex,
  },
  scoreContentControls: {
    display: "flex",
    justifyContent: "flex-end",
    overflow: "hidden",
  },
};

export default scoreFormControlsStyles;
