import { useScoresheetContext } from "../scoresheet.context";
import { UUID } from "gx-npm-lib";
import {
  CategoryCommentConfigType,
  CustomEvents,
  ScoresheetRequirementsCatDto,
} from "../../sections/score-content/score-content.types";
import { useEffect, useState } from "react";
import { saveCategoryComment } from "./category-comments.lib";
import { updateEditCount } from "../lib";

const useReqCategoryComments = (catId: UUID) => {
  const { initiativeId, initProductId, categories, setCategories, scoresheetEditCount, setScoresheetEditCount } =
    useScoresheetContext();
  const [commentId, setCommentId] = useState<UUID | "">("");
  const [commentDesc, setCommentDesc] = useState<string>("");

  useEffect(() => {
    const category = categories.find((req) => req.id === catId);
    if (category) {
      setCommentId(category.reqCategoryComment.id);
      setCommentDesc(category.reqCategoryComment.description);
    }
  }, [catId, categories]);

  useEffect(() => {
    window.dispatchEvent(
      new CustomEvent(CustomEvents.SCORESHEET_SAVING_COUNT, { detail: { count: scoresheetEditCount } })
    );
  }, [scoresheetEditCount]);

  const onSuccess = (newRequirements: ScoresheetRequirementsCatDto[]) => {
    setCategories(newRequirements);
  };
  const handleChangeCategoryComments = async (
    config: CategoryCommentConfigType,
    onError: (error: string | object) => void = (_error) => {
      /*do nothing*/
    }
  ) => {
    const { commentId: catCommentId, value } = config;
    const saveCategoryCommentConfig = { ...config, catId, initiativeId, initProductId, categories };
    const data = catCommentId ? { description: value } : { description: value, parentId: catId };
    updateEditCount(setScoresheetEditCount, 1);
    await saveCategoryComment(saveCategoryCommentConfig, data, onSuccess, onError).finally(() =>
      updateEditCount(setScoresheetEditCount, -1)
    );
  };

  return { commentId, commentDesc, handleChangeCategoryComments };
};

export default useReqCategoryComments;
