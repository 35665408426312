import React, { ReactElement, ReactNode } from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core";
import { listItemStyles } from "./styles";

type ListItemProps = {
  children: ReactNode;
  onBlur?: (...args: any[]) => void; // eslint-disable-line @typescript-eslint/no-explicit-any
  rootClassName?: string;
};

interface ChildElementProps {
  onBlur?: (...args: any[]) => void; // eslint-disable-line @typescript-eslint/no-explicit-any
}

const useStyles = makeStyles(() => listItemStyles);
const ListItem: React.FC<ListItemProps> = ({ children, onBlur, rootClassName = "" }) => {
  const childrenWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      const childElement = child as ReactElement<ChildElementProps>;
      return React.cloneElement(childElement, {
        onBlur: (...args: FocusEvent[]) => {
          if (onBlur) {
            onBlur(...args);
          }
          if (childElement.props && childElement.props.onBlur) {
            childElement.props.onBlur(...args);
          }
        },
      });
    }
    return child;
  });

  const classes = useStyles();
  const renderList = () => {
    return (
      <li className={classNames(classes.task, rootClassName, "no-hover")}>
        {childrenWithProps}
        <div style={{ clear: "both" }} />
      </li>
    );
  };

  return renderList();
};

export default ListItem;
