import { createContext, ReactNode, useContext, useState } from "react";
import {
  CategorySavingCountType,
  ScoresheetContextValue,
  ScoresheetRequirementsCatDto,
} from "../sections/score-content/score-content.types";
import {
  InitProdState,
  InitStatus,
  InitUserRole,
  ScorecardProgress,
  ScoringLevel,
  ScoringMode,
  UUID,
} from "gx-npm-lib";

const ScoresheetContext = createContext<ScoresheetContextValue | null>(null);

const useScoresheetContext = () => {
  const scoresheetContext = useContext(ScoresheetContext);

  if (scoresheetContext === null) {
    throw new Error("useScoresheetContext must be inside a ScoresheetContextProvider");
  }
  return scoresheetContext;
};

const ScoresheetContextProvider = (props: { children: ReactNode | Array<ReactNode> }) => {
  const [initiativeId, setInitiativeId] = useState<UUID>("");
  const [initProductId, setInitProductId] = useState<UUID>("");
  const [isEvaluator, setIsEvaluator] = useState<boolean>(false);
  const [categorySavingCount, setCategorySavingCount] = useState<CategorySavingCountType>(
    {} as CategorySavingCountType
  );
  const [keyTakeawaysDesc, setKeyTakeawaysDesc] = useState<string>("");
  const [keyTakeawaysId, setKeyTakeawaysId] = useState<UUID | "">("");
  const [categories, setCategories] = useState<ScoresheetRequirementsCatDto[]>([]);
  const [totalCategories, setTotalCategories] = useState<number>(0);
  const [totalAssignedCategories, setTotalAssignedCategories] = useState<number>(0);
  const [overallScore, setOverallScore] = useState<number>(0);
  const [scoresheetEditCount, setScoresheetEditCount] = useState<number>(0);
  const [scoringLevel, setScoringLevel] = useState<ScoringLevel | "">("");
  const [scoringMode, setScoringMode] = useState<ScoringMode | "">("");
  const [scorecardProgress, setScorecardProgress] = useState<ScorecardProgress | "">("");
  const [userRole, setUserRole] = useState<InitUserRole | "">("");
  const [productState, setProductState] = useState<InitProdState | "">("");
  const [initStatus, setInitStatus] = useState<InitStatus | "">("");

  const contextValue = {
    initiativeId,
    initProductId,
    initStatus,
    isEvaluator,
    categorySavingCount,
    keyTakeawaysDesc,
    keyTakeawaysId,
    overallScore,
    categories,
    scoresheetEditCount,
    scoringLevel,
    scoringMode,
    scorecardProgress,
    totalAssignedCategories,
    totalCategories,
    userRole,
    productState,
    setInitiativeId,
    setInitProductId,
    setInitStatus,
    setIsEvaluator,
    setCategorySavingCount,
    setKeyTakeawaysDesc,
    setKeyTakeawaysId,
    setOverallScore,
    setCategories,
    setScoresheetEditCount,
    setScoringLevel,
    setScoringMode,
    setScorecardProgress,
    setTotalAssignedCategories,
    setTotalCategories,
    setUserRole,
    setProductState,
  };
  return <ScoresheetContext.Provider value={contextValue}>{props.children}</ScoresheetContext.Provider>;
};

export { ScoresheetContext, ScoresheetContextProvider, useScoresheetContext };
