import React, { ReactNode } from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core";
import { listItemsContainerStyles as styles } from "./styles";

type ListItemsContainerProps = {
  children: ReactNode;
  rootClassName?: string;
};

const useStyles = makeStyles(() => styles);
const ListItemsContainer = ({ children, rootClassName = "" }: ListItemsContainerProps) => {
  const classes = useStyles();
  return (
    <section className={classNames(rootClassName)}>
      <ul className={classNames(classes.taskList)}>{children}</ul>
    </section>
  );
};

export default ListItemsContainer;
