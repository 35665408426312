import React from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { alertLevels, InlineAlert } from "gx-npm-ui";
import styles from "./none-evaluator-banner.styles";

const useStyles = makeStyles(() => styles);
const NoneEvaluatorBanner = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const message = t("This scorecard is a preview. You have not been assigned to score.");
  return (
    <div className={classNames(classes.root)}>
      <InlineAlert alertType={alertLevels.WARNING} textMessage={message} />
    </div>
  );
};

export default NoneEvaluatorBanner;
